* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.cdnfonts.com/css/gotham');
@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/mechanical');

body {
  font-family: 'Gotham', 'Poppins', 'Mechanical';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --colorPrimary-Blue: #2A6EDB;
  --colorPrimary-Green: #42C67E;
  --colorPrimary-Black: #232323;
  
  --colorSecondary-Green: #70DB83;
  --colorSecondary-Blue: #1AA4CC;
  --colorSecondary-Black: #959595;
  --colorSecondary-Gray: #E3E3E3;

  --colorPrimary-Error: #FF655A;

  --colorTertiary-Blue: #4884E2;

  --colorNeutral-Blanco: #FFF;
  --blur-shadow-to-button: rgba(42, 110, 219, 0.25) 0px 54px 55px, rgba(42, 110, 219, 0.12) 0px -12px 30px, rgba(42, 110, 219, 0.12) 0px 4px 6px, rgba(42, 110, 219, 0.17) 0px 12px 13px, rgba(42, 110, 219, 0.09) 0px -3px 5px;

}

/* .css-1nmlzef {
  height: initial !important;
}

.css-1u2w381-MuiModal-root-MuiDrawer-root {
  position: fixed !important;
  z-index: 9999999 !important;
} */

a {
  height: fit-content !important;
}

/* .css-10ipmgy-MuiPaper-root-MuiCard-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
} */

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
}

.MuiDialog-paper {
  margin-left: 28px !important;
  margin-right: 28px !important;
} */

/* .css-ypiqx9-MuiDialogContent-root {
  padding: 0px 0px !important;
}

.css-12rl710-MuiPaper-root-MuiDialog-paper {
  border-radius: 20px !important;
  margin: 10px !important;
}

.css-1ty026z {
  padding: 0 !important;
}

.css-1qmc5dd {
  border-radius: 20px !important;
}

.MuiDialog-paperScrollPaper {
  border-radius: 10px !important;
}

.css-yz9k0d-MuiInputBase-input {
  width: 70px !important;
  text-align: justify !important;
  text-justify: inter-word !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-width: 50% !important;
}

.css-1ujnqem-MuiTabs-root {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.MuiInputBase-root {
  margin-left: 25px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorBottom.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.MuiBox-root .css-1nm1zef {
  position: relative !important;
  z-index: 25 !important;
  height: initial !important;
} */

a {
  text-decoration: none !important;
}

/* .css-kjnuyh-MuiPaper-root-MuiCard-root {
  border-radius: 10px !important;
} */

/* .css-csmqbv-MuiPaper-root-MuiDialog-paper {
  width: 90vw !important;
  margin: 0 !important;
} */

/* .css-jcn4dz-MuiBadge-badge {
  background-color: #70DB83 !important;
}

.css-10ipmgy-MuiPaper-root-MuiCard-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
} */